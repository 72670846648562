













































import { BlFlexContainerMv, BlFlexItemAv, BlTextAv } from '@bukalapak/bazaar-mweb'
import Vue from 'vue'

import { alt } from './alt'
import { contacts } from './contacts'

export default Vue.extend({
  name: 'WhatsappFloatingButtonDesktop',
  components: { BlTextAv, BlFlexContainerMv, BlFlexItemAv },
  data() {
    return {
      isActive: false,
      contacts,
      alt,
    }
  },
  beforeDestroy() {
    document.body.style.overflowY = 'auto'
    this.isActive = false
  },
  methods: {
    toggleOverlay() {
      this.isActive = !this.isActive

      this.$nextTick(() => {
        const overlayEl = this.$refs.overlay as HTMLElement | undefined
        if (!overlayEl) return
        overlayEl.focus()
      })
    },
    closeOverlay(e: FocusEvent) {
      const currentTargetEl = e.currentTarget as HTMLElement
      const relatedTargetEl = e.relatedTarget as HTMLElement
      const logoEl = this.$refs.logo as HTMLElement

      if (!currentTargetEl.contains(relatedTargetEl) && relatedTargetEl !== logoEl) {
        this.isActive = false
      }
    },
  },
})
